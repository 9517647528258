import React from 'react';
import CookieConsent from 'react-cookie-consent';

import Layout from '../components/Layout';

import pic1 from '../assets/images/cello-2830350_1920_new.jpg';
import pic2 from '../assets/images/pexels-cottonbro-4033340_new.jpg';
import pic3 from '../assets/images/pexels-megapixelstock-17834_new.jpg';
import pic4 from '../assets/images/bach_1.jpg';
import pic5 from '../assets/images/summer-sunset.jpg';
import pic6 from '../assets/images/swan.jpg';
// import pic7 from '../assets/images/pic07.jpg';
import bassClef from '../assets/images/34502-200.png';
import bachArisio from '../assets/sounds/Bach_Arisio.mp3';
import summerTime from '../assets/sounds/Summetime.wav';
import theSwan from '../assets/sounds/The_Swan.wav';

import config from '../../config';

// eslint-disable-next-line react/prop-types
const AudioPlayer = ({ src }) => (
  <div
    style={{
      height: 36,
      marginBottom: 16,
    }}
  >
    <audio
      style={{ minWidth: 200, width: '100%', height: 36, outline: 'none' }}
      height={36}
      src={src}
      controls
      controlsList="nodownload"
    />
  </div>
);
const IndexPage = () => (
  <Layout>
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      buttonStyle={{ paddingTop: 0 }}
      declineButtonText="Decline"
      cookieName="gatsby-gdpr-google-analytics"
    >
      This website uses cookies to enhance the user experience.{' '}
    </CookieConsent>
    <section id="banner">
      <div className="inner">
        <div className="logo">
          <img src={bassClef} alt="" />
        </div>
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
      </div>
    </section>
    <section id="wrapper">
      <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <a href="/#" className="image">
            <img src={pic3} alt="" />
          </a>
          <div className="content">
            <h2 className="major">Weddings</h2>
            <p>
              Would you like live music for your ceremony or drinks reception? I
              can play solo cello for your service or as background music whilst
              you and your guests enjoy drinks before you sit down to eat. I
              will provide an ambient and relaxing musical background to your
              celebrations and I will provide you with a repertoire list to
              peruse so that you can choose some of your favourites; or just sit
              back, relax and allow me to tailor a programme that I think will
              suit you and your guests. <br />
              <br />
              Should you wish to have a cellist for your service, I can play
              pre-ceremony whilst guests are arriving and you can choose a piece
              for the entrance of the bride/groom, signing of the register and
              when you both leave the church or ceremony room. <br />
              <br />
              It is possible for me to play outside but, being an acoustic
              instrument, there are some weather conditions that I am unable to
              play in. <br />
              <br />I have no amplification and, whilst the sound of my cello
              carries very well for the most part, it tends to get a little lost
              during the wedding breakfast because guests are usually quite
              vocal by then and it would be a shame to not be heard! For this
              reason, I have decided to offer just ceremony (either church or
              civil) and drinks reception. I have a minimum booking of one hour.
              As a rough guide the time required would be as follows: <br />
              <br />
              Civil Ceremony – 1 hour, which would allow for time when the
              guests arrive and start to be seated in the ceremony area and the
              actual ceremony itself.
              <br />
              <br />
              Church Ceremony – 1 to 1 ½ hours as a church ceremony is usually
              longer than a civil ceremony.
              <br />
              <br />
              Drinks reception – 1 – 2 hours depending on how many photographs
              will be taken. Of course, every wedding is different, and timings
              usually become clearer once arrangements get underway. <br />
              <br />
              <br />
              Fees: <br />
              <br />1 hour - £175
              <br />1 ½ hours - £225
              <br />2 hours - £275 <br />
              <br />
              All prices are fully inclusive within a 50-mile radius of my house
              (LE17). Travel outside this range may incur a small fee to cover
              fuel costs. Prices are from the first notes to the last and
              include any time travelled between venues (for example church to
              reception venue).
            </p>
            <h2 className="major">Functions</h2>
            <p>
              Functions My instrument is purely acoustic and so does not work
              for very large gatherings where there will be lots of loud
              chatter. However, if you are having a garden party, birthday or
              anniversary bash or any celebration where ambient, background
              music will add to the atmosphere then solo cello will work for
              you. I also think that the cello is particularly beautiful when
              played at funerals. <br />
              <br />
              Please contact me for further information as to how you think my
              music might work for you. <br />
              <br />
              Fees are as follows: <br />
              <br />1 hour - £175
              <br />1 ½ hours - £225
              <br />2 hours - £275 <br />
              <br />
            </p>
          </div>
        </div>
      </section>

      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/#" className="image">
            <img src={pic2} alt="" />
          </a>
          <div className="content">
            <h2 className="major">Cello and Piano Tuition</h2>
            <p>
              I have been teaching both the cello and the piano for nearly
              thirty years and I combine my knowledge of performance together
              with my vast experience and ability to understand the needs of
              each and every pupil. I have taught in schools in London,
              Nottingham, Derbyshire, Leicestershire and Northamptonshire, which
              include Trent College and the Nottingham High School for Girls.
              Alongside private and school teaching, mainly on a one-to-one
              basis but also in groups (cello only), I also have a long career
              in coaching ensembles, from quartets to cello sectionals to larger
              string groups. Students of all ages are welcome, although I advise
              that a child should be no younger than five to start either
              instrument. I teach from a fully equipped studio at home and I am
              able to teach the cello to all ages and abilities, from beginner
              to diploma standard. Students would need to buy or rent a cello
              and would also need to have a few basic books and pieces of
              equipment, all of which I can advise on. I can teach piano from
              beginner to grade 5 and all students would need a keyboard or
              piano at home so that they are able to practice in between
              lessons. They would also need to purchase some music books from
              which to learn, which I will advise on.
            </p>
          </div>
        </div>
      </section>

      <section id="three" className="wrapper spotlight style3">
        <div className="inner">
          <a href="/#" className="image">
            <img src={pic1} alt="" />
          </a>
          <div className="content">
            <h2 className="major">Students</h2>
            <p>
              Students are required to pay for half a term in advance and to
              give half a term’s notice if they wish to cease lessons. I am more
              than happy to give a pupil a few ‘taster’ lessons to see if they
              are going to enjoy the challenge of learning a new instrument.
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}>
                I am fully insured and hold full and current DBS (CRB) checks.
              </span>
            </p>
          </div>
        </div>
      </section>

      <section id="music" className="wrapper alt style1">
        <div className="inner">
          <h2 className="major">Music</h2>
          <p>Below are some clips from my repertoire.</p>
          <section className="features">
            <article>
              <a href="/#" className="image">
                <img src={pic4} alt="" />
              </a>
              <h3 style={{ marginTop: 16 }} className="major">
                Bach - Arioso
              </h3>
              <p>
                Arioso is a type of solo vocal piece, usually occurring in an
                opera or oratorio, falling somewhere between recitative and aria
                in style. Literally, arioso means airy. The term arose in the
                16th century
              </p>
              {/* <a href="/#" className="special">
                Learn more
              </a> */}
              <AudioPlayer src={bachArisio} />
            </article>
            <article>
              <a href="/#" className="image">
                <img src={pic5} alt="" />
              </a>
              <h3 style={{ marginTop: 16 }} className="major">
                Summertime for Piano
              </h3>
              <p style={{ marginBottom: 64 }}>
                Written in 1934, ‘Summertime’ was one of the first compositions
                George Gershwin worked on for his brand-new opera Porgy and
                Bess.
              </p>
              <AudioPlayer src={summerTime} />
            </article>
            <article>
              <a href="/#" className="image">
                <img src={pic6} alt="" />
              </a>
              <h3 style={{ marginTop: 16 }} className="major">
                The Swan
              </h3>
              <p>
                The Swan, is the 13th and penultimate movement of The Carnival
                of the Animals by Camille Saint-Saëns. Originally scored for
                solo cello accompanied by two pianos, it has been arranged and
                transcribed for many instruments but remains best known as a
                cello solo.
              </p>
              <AudioPlayer src={theSwan} />
            </article>
            {/* <article>
              <a href="/#" className="image">
                <img src={pic7} alt="" />
              </a>
              <h3 className="major">Fusce consequat</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing vehicula id
                nulla dignissim dapibus ultrices.
              </p>
              <a href="/#" className="special">
                Learn more
              </a>
            </article> */}
          </section>
          {/* <ul className="actions">
            <li>
              <a href="/#" className="button">
                Browse All
              </a>
            </li>
          </ul> */}
        </div>
      </section>
    </section>
  </Layout>
);

export default IndexPage;
